import { React, useState } from 'react'
import Button from 'react-bootstrap/Button';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { SystemSecurityUpdate } from '@mui/icons-material';
import "./show3.css"
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Link } from "react-router-dom";



function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <div style={{ padding: "30px 50px" }}>
                <DialogTitle>Order totals</DialogTitle>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "30px" }}>
                    <span>Parking fee</span>
                    <span>0.00 CHF</span>
                </div>
                <hr></hr>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "30px" }}>
                    <h6 style = {{fontSize:"1.15rem", fontWeight:"500", color:"#4d4d4d"}}>Total cost</h6>
                    <h6 style = {{fontSize:"1.15rem", fontWeight:"500", color:"#4d4d4d"}}>0.00 CHF</h6>
                </div>
                <Button style={{ margin: "10px 0" }} variant="second" size="small" onClick={handleClose} className="confirm-button">OK</Button>
            </div>

        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};


export default function Show3(props) {
    const [val, setVal] = useState(props.pInfo);
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };


    const curr = val.duration;
    const d = new Date();
    const dt = d.toLocaleString('en-GB', { timeZone: 'Europe/London' });
    const curHours = dt.split(" ")[1].split(":")[0];
    let curHoursI = Number(curHours);
    const curMinutes = dt.split(" ")[1].split(":")[1];
    const curMinutesI = Number(curMinutes);
    let time = "";
    let dFormat = "AM";
    let time2 = 0;
    let today = "today";
    if (curHoursI > 12) {
        dFormat = "PM";
        curHoursI = curHoursI - 12;
    }
    if (curr.split(' ')[1] === "minutes") {
        time = "4 hours " + (60 - curMinutesI) + " " + "minutes";
        time2 = 5;
        curHoursI = curHoursI + time2;
        if (curHoursI > 12) {
            if (dFormat === "PM") {
                dFormat = "AM";
            }
            else dFormat = "PM";
            curHoursI = curHoursI - 12;
        }
    }
    else if (curr.split(' ')[1] === "hours") {
        time = "12 hours" + " " + (60 - curMinutesI) + " " + "minutes";
        time2 = 12;
        if (dFormat === "PM") dFormat = "AM";
        else dFormat = "PM";
    }
    else if (curr.split(' ')[1] === "days") {
        time = "1 day";
        today = "tomorrow";
    }

    const handleCancel = () => {
        props.fct("item0", props.pInfo);
    }
    return (
        <>
            <p>Please confirm your parking</p>
            <div className="park-white-box">
                <div className="park-info">
                    <p className="add-grey">Parking duration</p>
                    <h3 style={{ color: "#191978" }}>Starting now</h3>
                    
                    <div className="info-container">
                        <div>
                            <LocationOnOutlinedIcon className="icon-location" />
                            <span>{val.location}</span>
                        </div>
                        <div style={{ gap: "5px" }}>
                            
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div style={{ paddingLeft: "20px" }}>
                    <p className="add-grey">Total charges</p>
                    <h3>0.00 CHF</h3>
                    <p style={{ paddingBottom: "10px", color: "#191978" }} onClick={handleClickOpen}>View totals</p>
                    <SimpleDialog
                        selectedValue={selectedValue}
                        open={open}
                        onClose={handleClose}
                    />
                </div>
            </div>
            
            <Link to="/pay" style = {{textDecoration:"none", color:"white"}}>
                <Button style={{ marginBottom: "20px" }} variant="second" size="medium" className={`confirm-button  ${!val.nrValid ? "confirm-button-enabled" : ""}`}>
            PARK
            </Button>
            </Link>
        </>
    )
}
